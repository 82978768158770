import Module from "xolabot-sdk";

class ResumeBookingModule extends Module {
    bookingResumed = false;
    conversion = false;

    constructor(options) {
        super("resume_booking", options);

        // Register module with name and list of events and handlers.

        // We need to delay the execution since `order_info` may come in late.
        // X1
        this.bus.once({ channel: this.channels.CHECKOUT, type: "dropped" }, (draft) => {
            setTimeout(() => this.handleCheckoutDrop(draft), 500);
        });

        // X2
        this.bus.once({ channel: this.channels.CHECKOUT, type: "purchase_dropped" }, (purchase) => {
            setTimeout(() => this.handleCheckoutPurchaseDrop(purchase), 500);
        });

        this.bus.on({ channel: this.channels.CHECKOUT, type: "closed" }, this.handleCheckoutClosed);
        this.bus.on({ channel: this.channel, type: "confirm" }, this.handleConfirmButtonClick);
        this.bus.on({ channel: this.channel, type: "cancel" }, this.handleCancelButtonClick);

        // X1
        this.stopHandleTagOrders = this.bus.on(
            { channel: this.channels.CHECKOUT_COLLECTOR, type: "order_info" },
            this.handleTagOrdersAndPurchases,
        );

        // X2
        this.stopHandleTagPurchases = this.bus.on(
            { channel: this.channels.CHECKOUT_COLLECTOR, type: "purchase_info" },
            this.handleTagOrdersAndPurchases,
        );

        // X1
        this.stopRecordConversion = this.bus.on(
            { channel: this.channels.CHECKOUT_COLLECTOR, type: "order_info" },
            this.recordConversion,
        );

        // X2
        this.stopRecordPurchaseConversion = this.bus.on(
            { channel: this.channels.CHECKOUT_COLLECTOR, type: "purchase_info" },
            this.recordConversion,
        );
    }

    safeDrop() {
        this.drop && this.drop();
    }

    recordConversion = ({ purchased }) => {
        if (purchased) {
            this.conversion = true;
            this.stopRecordConversion();
            this.stopRecordPurchaseConversion();
        }
    };

    handleCheckoutClosed = () => {
        setTimeout(() => {
            if (this.bookingResumed && !this.conversion) {
                this.bookingResumed = false;
                this.safeDrop();
            }
        }, 1000);
    };

    handleTagOrdersAndPurchases = ({ reached_payment_page }) => {
        if (reached_payment_page && this.bookingResumed) {
            this.checkout.xwm.checkout.send("tag_orders", "XolaBot");
            this.checkout.xwm.checkout.send("tag_orders", "XolaBot - Resume Booking");
            this.stopHandleTagOrders();
            this.stopHandleTagPurchases();
        }
    };

    /**
     * Important: There's a bug currently on checkout when `dropped` event is fired.
     * Cart always reports `totalProcessed` as zero so we're unable to determine if
     * there was a successful purchase, so this method is fired always.
     *
     * To avoid this issue, we're also tracking `purchased` flag from `order_info` event.
     */
    handleCheckoutDrop = async (draft) => {
        if (this.conversion) {
            return;
        }

        // Save draft to local storage.
        this.setState({ draft });

        // Calculate score.
        const score = { value: draft.data.amount, confidence: 0.5 };

        const productNames = draft.data.items.map((item) => item.name).join(" and ");

        await this.sleep(2000);

        this.notify(score, {
            message: `Are you still interested in ${productNames}?`,
            buttons: [
                { label: "Resume Booking", event: { channel: this.channel, type: "confirm" }, primary: true },
                { label: "No", event: { channel: this.channel, type: "cancel" } },
            ],
        });
    };

    // X2
    handleCheckoutPurchaseDrop = async (purchase) => {
        if (this.conversion) {
            return;
        }

        // Save purchase to local storage.
        this.setState({ purchase });

        // Calculate score.
        const score = { value: purchase.amount, confidence: 0.5 };

        const productNames = purchase.items.map((item) => item.name).join(" and ");

        await this.sleep(2000);

        this.notify(score, {
            message: `Are you still interested in ${productNames}?`,
            buttons: [
                { label: "Resume Booking", event: { channel: this.channel, type: "confirm" }, primary: true },
                { label: "No", event: { channel: this.channel, type: "cancel" } },
            ],
        });
    };

    hideChatButtons() {
        this.bus.emit({ channel: this.channels.UI, type: "hide_chat_buttons" });
        this.bus.emit({ channel: this.channels.UI, type: "read_chat_messages" });
    }

    handleConfirmButtonClick = async () => {
        this.hideChatButtons();

        this.bus.emit(
            { channel: this.channels.UI, type: "add_chat_message" },
            {
                body: "Yes, resume booking",
                read: true,
                user: true,
            },
        );

        await this.sleep(500);

        this.bus.emit(
            { channel: this.channels.UI, type: "add_chat_message" },
            {
                body: "Awesome, opening checkout...",
                read: true,
            },
        );

        await this.sleep(500);

        // X1 - Open Checkout using Draft
        if (this.state.draft) {
            this.bookingResumed = true;

            this.checkout.checkoutClose();

            setTimeout(() => {
                this.checkout.checkout({
                    seller: this.state.draft.seller.id,
                    draft: this.state.draft.id,
                });

                this.clearState();
            }, 500);
        }

        // X2 - Open Checkout using Draft Purchase
        if (this.state.purchase) {
            this.bookingResumed = true;

            this.checkout.checkoutClose();

            setTimeout(() => {
                this.checkout.checkout({
                    seller: this.state.purchase.seller.id,
                    abandon: this.state.purchase.id,
                    clientSecret: this.state.purchase.clientSecret,
                });

                this.clearState();
            }, 500);
        }
    };

    handleCancelButtonClick = async () => {
        this.hideChatButtons();
        this.safeDrop();

        this.bus.emit(
            { channel: this.channels.UI, type: "add_chat_message" },
            {
                body: "No, thanks",
                read: true,
                user: true,
            },
        );

        await this.sleep(500);

        this.bus.emit(
            { channel: this.channels.UI, type: "add_chat_message" },
            {
                body: "Ok, have a nice day!",
                read: true,
            },
        );
    };
}

ResumeBookingModule.load();
